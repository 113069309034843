<template>
  <div class="product-sxl" data-id="11687571" id="s-page-container">
    <div>
      <div id="s-content"
           class="s-variation-default s-custom-colors  s-font-heading-hei s-font-title-hei s-font-body-hei s-font-button-hei s-font-weight-heading-default s-font-weight-title-default s-font-weight-body-default s-font-weight-button-default s-theme-alignment-left"
           lang="zh" style="padding-bottom: 0px;">
        <div style="margin: auto;">
          <div class="social-thumbnail">
            <div style="display: none;"></div>
          </div>

          <div
            class="s-bg-image s-bg-blurred s-bg-dark-text s-new-hero-section s-section s-hero-section _animate-background background-image transition lazyloaded"
            data-react-style="{&quot;backgroundRepeat&quot;:&quot;repeat&quot;,&quot;backgroundSize&quot;:&quot;auto&quot;,&quot;backgroundColor&quot;:&quot;transparent&quot;,&quot;backgroundPosition&quot;:&quot;50% 50%&quot;}"
            style="background-repeat: repeat; background-size: auto; background-color: transparent; background-position: 0px -152.3px; background-image: url(&quot;//user-assets.sxlcdn.com/images/515269/Fhqr-MqCoXlf10GaWDepKssS7oFP.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/quality/90!/format/png&quot;); background-attachment: fixed;">
            <div class="container s-rva s-layout-buttonLeft">
              <div class="s-rva-media eight columns ">
                <div class="s-component s-media  s-animation-page-slide_in">
                  <div>
                    <div class="s-component-content">
                      <div class="s-img-wrapper"><img src="https://oss.tccms.cn/1ox2Kl5B1zG" alt=""
                                                      title="" class="crop-default" data-description="" width="1200"
                                                      height="auto" style="aspect-ratio: 1200 / 734;"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="s-rva-text eight columns ">
                <div class="half-offset-right">
                  <div class="s-title-group  ">
                    <div class="s-title ">
                      <div class="s-component s-text s-animation-page-slide_in">
                        <h1 class="s-component-content s-font-title"><p style="font-size: 80%;">
                          <strong>淘才云城市招聘解决方案</strong></p></h1>
                      </div>
                    </div>
                    <div class="s-subtitle">
                      <div class="s-component s-text s-animation-page-slide_in">
                        <h4 class="s-component-content s-font-heading"><p style="font-size: 100%;">
                          致力于帮助区域互联网企业搭建招聘平台</p>
                          <p style="font-size: 100%;">提供完整的智能化招聘解决方案</p></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-2 css-1rkmeg4 " id="section-f_798fb7fc-a76e-4153-939f-61a7726f0b4a">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class="background-image background-image transition lazyloaded  s-new-media-section s-section s-media-section     _animate-background background-image"
              data-react-style="{}" style="background-attachment: fixed;">
              <div class="container">
                <div class="columns sixteen"></div>
                <div class="s-mh s-layout-normal s-repeatable">
                  <div>
                    <div data-sorting-index="0" class="s-repeatable-item s-last-row">
                      <div class="clearfix">
                        <div>
                          <div class="s-item-media-wrapper s-mhi center-align">
                            <div class="s-item-media-group">
                              <div class="s-component s-media  s-animation-page-slide_in">
                                <div>
                                  <div class="s-component-content">
                                    <div class="s-img-wrapper"><img
                                      src="https://oss.tccms.cn/1ox2wZgpUL5" alt="" title=""
                                      class="crop-default" data-description=""></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="">
                            <div class="s-item-text-group  s-animation-page-slide_in">
                              <div class="s-item-title">
                                <div class="s-component s-text">
                                  <h3 class="s-component-content s-font-heading"><p
                                    style="text-align: center; font-size: 160%;">&nbsp;</p>
                                    <p style="text-align: center; font-size: 160%;"><span class="s-text-color-black">淘才直聘，全方位的招聘平台</span>
                                    </p></h3>
                                </div>
                              </div>
                              <div class="s-item-text">
                                <div class="s-component s-text">
                                  <div class="s-component-content s-font-body"><p style="text-align: center;"><span
                                    class="s-text-color-black">为企业提供方便快捷的招聘功能，为个人提供多平台投递方式，多渠道打通，高效推进招聘流程</span></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-3 css-1rkmeg4 " id="section-f_7ac9bba5-84af-4e70-ab19-ec6da45bbb3d">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class="background-image background-image background-image transition lazyloaded s-bg-image s-bg-blurred s-bg-dark-text s-new-hero-section s-section s-hero-section   _animate-background background-image"
              data-bg="//user-assets.sxlcdn.com/images/515269/Fqx4Id3mNUkb_hRxIG51tJQzliBA.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/format/png"
              data-react-style="{&quot;backgroundRepeat&quot;:&quot;repeat&quot;,&quot;backgroundSize&quot;:&quot;auto&quot;,&quot;backgroundColor&quot;:&quot;transparent&quot;,&quot;backgroundPosition&quot;:&quot;50% 50%&quot;}"
              style="background-repeat: repeat; background-size: auto; background-color: transparent; background-position: 0px -653.572px; background-image: url(&quot;//user-assets.sxlcdn.com/images/515269/Fqx4Id3mNUkb_hRxIG51tJQzliBA.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/format/png&quot;); background-attachment: fixed;">
              <div class="loaded css-1tnf9eu css-1cd44ws0"></div>
              <div class="container s-rva s-layout-buttonLeft">
                <div class="s-rva-media eight columns ">
                  <div class="s-component s-media  s-animation-page-slide_in">
                    <div>
                      <div class="s-component-content">
                        <div class="s-img-wrapper">
                          <div class="s-ratio-box" style="max-width: 1200px; max-height: 872px;">
                            <div class="s-ratio-fill" style="padding-bottom: 72.67%;"></div>
                            <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                            data-description="" width="1200" height="872"
                                                            src="https://oss.tccms.cn/1ox2wdTXydy"
                                                            >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="s-rva-text eight columns ">
                  <div class="half-offset-right">
                    <div class="s-title-group  ">
                      <div class="s-title ">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h2 class="s-component-content s-font-title"><p style="font-size: 60%;">企业招聘全流程管理</p>
                            <p style="font-size: 60%;">招聘进展了若指掌</p></h2>
                        </div>
                      </div>
                      <div class="s-subtitle">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h4 class="s-component-content s-font-heading"><p style="font-size: 80%;">
                            企业招聘职位的创建，置顶，刷新等职位多功能管理</p>
                            <p style="font-size: 80%;">针对求职者简历下载、打印、沟通、面试等一站式简历管理</p>
                            <p style="font-size: 80%;">即时招聘通知，通过微信发送求职者投递简历消息提醒，即时把握动态</p></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-4 css-1rkmeg4 " id="section-f_f2acb369-365c-4874-8209-8d898c4db349">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class="background-image background-image transition lazyloaded s-no-bg  s-new-hero-section s-section s-hero-section   _animate-background background-image"
              data-react-style="{}" style="background-attachment: fixed;">
              <div class="container s-rva s-layout-buttonRight">
                <div class="s-rva-media eight columns right">
                  <div class="s-component s-media  s-animation-page-slide_in">
                    <div>
                      <div class="s-component-content">
                        <div class="s-img-wrapper">
                          <div class="s-ratio-box" style="max-width: 1200px; max-height: 1043px;">
                            <div class="s-ratio-fill" style="padding-bottom: 86.92%;"></div>
                            <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                            data-description="" width="1200" height="1043"
                                                            src="https://oss.tccms.cn/1ox2wnxEfcX"
                                                          >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="s-rva-text eight columns right">
                  <div class="half-offset-left">
                    <div class="s-title-group  ">
                      <div class="s-title ">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h2 class="s-component-content s-font-title"><p style="font-size: 60%;"><span
                            class="s-text-color-black">全面的功能设定</span></p>
                            <p style="font-size: 60%;"><span class="s-text-color-black">让求职者快速找到理想工作</span></p></h2>
                        </div>
                      </div>
                      <div class="s-subtitle">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h4 class="s-component-content s-font-heading"><p style="font-size: 80%;">完善便捷的简历管理及刷新功能</p>
                            <p style="font-size: 80%;">职位分类，检索，筛选等精确定位，便于求职者更快找到理想工作</p>
                            <p style="font-size: 80%;">浏览、收藏、投递记录、微信提醒等功能帮助求职者迅速求职</p></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-5 css-1rkmeg4 " id="section-f_d9838a69-64ec-449c-9bf6-56449e395c02">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class="background-image background-image background-image transition lazyloaded s-bg-image s-bg-blurred s-bg-dark-text s-new-hero-section s-section s-hero-section   _animate-background background-image"
              data-bg="//user-assets.sxlcdn.com/images/515269/Fqx4Id3mNUkb_hRxIG51tJQzliBA.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/format/png"
              data-react-style="{&quot;backgroundRepeat&quot;:&quot;repeat&quot;,&quot;backgroundSize&quot;:&quot;auto&quot;,&quot;backgroundColor&quot;:&quot;transparent&quot;,&quot;backgroundPosition&quot;:&quot;50% 50%&quot;}"
              style="background-repeat: repeat; background-size: auto; background-color: transparent; background-position: 0px -642.561px; background-image: url(&quot;//user-assets.sxlcdn.com/images/515269/Fqx4Id3mNUkb_hRxIG51tJQzliBA.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/format/png&quot;); background-attachment: fixed;">
              <div class="loaded css-1tnf9eu css-1cd44ws0"></div>
              <div class="container s-rva s-layout-buttonLeft">
                <div class="s-rva-media eight columns ">
                  <div class="s-component s-media ">
                    <div>
                      <div class="s-component-content">
                        <div class="s-img-wrapper">
                          <div class="s-ratio-box" style="max-width: 1200px; max-height: 1114px;">
                            <div class="s-ratio-fill" style="padding-bottom: 92.83%;"></div>
                            <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                            data-description="" width="1200" height="1114"
                                                            src="https://oss.tccms.cn/1ox2wh3iJuH"
                                                            >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="s-rva-text eight columns ">
                  <div class="half-offset-right">
                    <div class="s-title-group  ">
                      <div class="s-title ">
                        <div class="s-component s-text">
                          <h2 class="s-component-content s-font-title"><p style="font-size: 60%;">多平台渠道展现高效推进招聘流程</p>
                          </h2>
                        </div>
                      </div>
                      <div class="s-subtitle">
                        <div class="s-component s-text">
                          <h4 class="s-component-content s-font-heading"><p style="font-size: 80%;">
                            招聘门户网站、H5移动端、微信小程序多方位展现</p>
                            <p style="font-size: 80%;">企业及职位海报生成、信息分享多渠道推广迅速扩散</p>
                            <p style="font-size: 80%;">求职者随时随地接收信息并迅速反馈，高效推进招聘流程</p></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-6 css-1rkmeg4 " id="section-f_1ed16ccb-b465-4f32-9955-1c57c60b766c">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class=" background-image background-image transition lazyloaded s-no-bg  s-new-hero-section s-section s-hero-section   _animate-background background-image"
              data-react-style="{}" style="background-attachment: fixed;">
              <div class="container s-rva s-layout-buttonRight">
                <div class="s-rva-media eight columns right">
                  <div class="s-component s-media ">
                    <div>
                      <div class="s-component-content">
                        <div class="s-img-wrapper">
                          <div class="s-ratio-box" style="max-width: 1200px; max-height: 1107px;">
                            <div class="s-ratio-fill" style="padding-bottom: 92.25%;"></div>
                            <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                            data-description="" width="1200" height="1107"
                                                            src="https://oss.tccms.cn/1ox2wr7DfUV"
                                                            >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="s-rva-text eight columns right">
                  <div class="half-offset-left">
                    <div class="s-title-group  ">
                      <div class="s-title ">
                        <div class="s-component s-text">
                          <h2 class="s-component-content s-font-title"><p style="font-size: 60%;"><span
                            class="s-text-color-black">线上即时沟通提高招聘与求职效率</span></p></h2>
                        </div>
                      </div>
                      <div class="s-subtitle">
                        <div class="s-component s-text">
                          <h4 class="s-component-content s-font-heading"><p style="font-size: 80%;">
                            在线聊功能让企业与求职者直接聊天沟通</p>
                            <p style="font-size: 80%;">让企业与求职者之间信息公开透明，随时随地开聊</p>
                            <p style="font-size: 80%;">提高招聘与求职效率</p></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-7 css-1rkmeg4 " id="section-f_8813c2c2-8fde-47e6-9db8-041d8c2812a8">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class=" background-image background-image background-image transition lazyloaded s-bg-image s-bg-blurred s-bg-dark-text s-new-hero-section s-section s-hero-section   _animate-background background-image"
              data-bg="//user-assets.sxlcdn.com/images/515269/Fqx4Id3mNUkb_hRxIG51tJQzliBA.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/format/png"
              data-react-style="{&quot;backgroundRepeat&quot;:&quot;repeat&quot;,&quot;backgroundSize&quot;:&quot;auto&quot;,&quot;backgroundColor&quot;:&quot;transparent&quot;,&quot;backgroundPosition&quot;:&quot;50% 50%&quot;}"
              style="background-repeat: repeat; background-size: auto; background-color: transparent; background-position: 0px -617.075px; background-image: url(&quot;//user-assets.sxlcdn.com/images/515269/Fqx4Id3mNUkb_hRxIG51tJQzliBA.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/format/png&quot;); background-attachment: fixed;">
              <div class="loaded css-1tnf9eu css-1cd44ws0"></div>
              <div class="container s-rva s-layout-buttonLeft">
                <div class="s-rva-media eight columns ">
                  <div class="s-component s-media  s-animation-page-slide_in">
                    <div>
                      <div class="s-component-content">
                        <div class="s-img-wrapper">
                          <div class="s-ratio-box" style="max-width: 1200px; max-height: 873px;">
                            <div class="s-ratio-fill" style="padding-bottom: 72.75%;"></div>
                            <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                            data-description="" width="1200" height="873"
                                                            src="https://oss.tccms.cn/1owPfCbXM7U"
                                                          >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="s-rva-text eight columns ">
                  <div class="half-offset-right">
                    <div class="s-title-group  ">
                      <div class="s-title ">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h2 class="s-component-content s-font-title"><p style="font-size: 60%;">全方位数据统计</p>
                            <p style="font-size: 60%;">可针对性优化客户解决方案</p></h2>
                        </div>
                      </div>
                      <div class="s-subtitle">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h4 class="s-component-content s-font-heading"><p style="font-size: 80%;">
                            将企业、职位、求职者、简历库、投递数、活跃度等多种数据进行统计</p>
                            <p style="font-size: 80%;">实现全局分析与可视化呈现</p>
                            <p style="font-size: 80%;">可快速针对性优化客户方案</p></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-8 css-1rkmeg4 " id="section-f_6e563b8a-1eec-4a41-a9c2-eab6e0ef5e9a">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class=" background-image background-image transition lazyloaded s-no-bg  s-new-hero-section s-section s-hero-section   _animate-background background-image"
              data-react-style="{}" style="background-attachment: fixed;">
              <div class="container s-rva s-layout-buttonRight">
                <div class="s-rva-media eight columns right">
                  <div class="s-component s-media  s-animation-page-slide_in">
                    <div>
                      <div class="s-component-content">
                        <div class="s-img-wrapper">
                          <div class="s-ratio-box" style="max-width: 1200px; max-height: 811px;">
                            <div class="s-ratio-fill" style="padding-bottom: 67.58%;"></div>
                            <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                            data-description="" width="1200" height="811"
                                                            src="https://oss.tccms.cn/1ox2L6iZCw2">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="s-rva-text eight columns right">
                  <div class="half-offset-left">
                    <div class="s-title-group  ">
                      <div class="s-title ">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h2 class="s-component-content s-font-title"><p style="font-size: 60%;"><span
                            class="s-text-color-black">CRM客户管理系统</span></p></h2>
                        </div>
                      </div>
                      <div class="s-subtitle">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h4 class="s-component-content s-font-heading"><p style="font-size: 80%;">与管理系统数据同步，更效率更便捷</p>
                            <p style="font-size: 80%;">客服实时跟踪客户，及时解决问题，增加客户满意度</p></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-9 css-1rkmeg4 " id="section-f_7b2dfca2-6d35-49ae-829b-ebefe4e81561">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class=" background-image background-image background-image transition lazyloaded s-bg-image s-bg-blurred s-bg-dark-text s-new-hero-section s-section s-hero-section   _animate-background background-image"
              data-bg="//user-assets.sxlcdn.com/images/515269/Fqx4Id3mNUkb_hRxIG51tJQzliBA.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/format/png"
              data-react-style="{&quot;backgroundRepeat&quot;:&quot;repeat&quot;,&quot;backgroundSize&quot;:&quot;auto&quot;,&quot;backgroundColor&quot;:&quot;transparent&quot;,&quot;backgroundPosition&quot;:&quot;50% 50%&quot;}"
              style="background-repeat: repeat; background-size: auto; background-color: transparent; background-position: 0px -265.869px; background-image: url(&quot;//user-assets.sxlcdn.com/images/515269/Fqx4Id3mNUkb_hRxIG51tJQzliBA.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/format/png&quot;); background-attachment: fixed;">
              <div class="loaded css-1tnf9eu css-1cd44ws0"></div>
              <div class="container s-rva s-layout-buttonLeft">
                <div class="s-rva-media eight columns ">
                  <div class="s-component s-media  s-animation-page-slide_in">
                    <div>
                      <div class="s-component-content">
                        <div class="s-img-wrapper">
                          <div class="s-ratio-box" style="max-width: 1200px; max-height: 1147px;">
                            <div class="s-ratio-fill" style="padding-bottom: 95.58%;"></div>
                            <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                            data-description="" width="1200" height="1147"
                                                            src="https://oss.tccms.cn/1ox2wzEWcNV"
                                                            >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="s-rva-text eight columns ">
                  <div class="half-offset-right">
                    <div class="s-title-group  ">
                      <div class="s-title ">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h2 class="s-component-content s-font-title"><p style="font-size: 60%;">应用中心，搭建在线运营场景</p></h2>
                        </div>
                      </div>
                      <div class="s-subtitle">
                        <div class="s-component s-text s-animation-page-slide_in">
                          <h4 class="s-component-content s-font-heading"><p style="font-size: 80%;">
                            广告功能，公告功能，线下招聘会预定展位功能，企业及职位生成海报功能，</p>
                            <p style="font-size: 80%;">场景二维码功能，等多种扩展辅助功能，为您提供更全面的解决方案</p></h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-10 css-1rkmeg4 " id="section-f_51da7263-6ff0-4c6e-a342-9e41b6e48a8a">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div
            class=" background-image background-image background-image transition lazyloaded s-bg-image s-bg-blurred s-bg-light-text s-block-section s-section   _animate-background background-image"
            data-bg="//user-assets.sxlcdn.com/images/515269/Fphjw87_eAWgeMgheIbDpkUu0E04.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/quality/90!/format/png"
            data-react-style="{&quot;backgroundRepeat&quot;:&quot;no-repeat&quot;,&quot;backgroundSize&quot;:&quot;cover&quot;,&quot;backgroundColor&quot;:&quot;transparent&quot;,&quot;backgroundPosition&quot;:&quot;50% 50%&quot;}"
            style="background-repeat: no-repeat; background-size: 2837.97px 867px; background-color: transparent; background-position: -489.985px 249.408px; background-image: url(&quot;//user-assets.sxlcdn.com/images/515269/Fphjw87_eAWgeMgheIbDpkUu0E04.png?imageMogr2/strip/auto-orient/thumbnail/2000x1500>/quality/90!/format/png&quot;); background-attachment: fixed;">
            <div class="loaded css-1tnf9eu css-1cd44ws0"></div>
            <div class="container">
              <div class="columns sixteen">
                <div class="s-title-group  ">
                  <div class="s-title sb-title-center-align">
                    <div class="s-component s-text s-animation-page-slide_in">
                      <h2 class="s-component-content s-font-title">
                        <div style="font-size: 80%; text-align: center;">每周小迭代 每月大迭代 不停的版本升级</div>
                      </h2>
                    </div>
                  </div>
                  <div class="s-subtitle">
                    <div class="s-component s-text s-animation-page-slide_in">
                      <h4 class="s-component-content s-font-heading">
                        <div style="font-size: 80%; text-align: center;">产品功能不断优化升级 淘才云招聘携手与您一起共创未来</div>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="alignment-container center-align">
                <div class="s-repeatable s-block s-component s-mh">
                  <div class="s-block-item s-repeatable-item s-block-sortable-item  image s-last-row">
                    <div>
                      <div>
                        <div class="s-block-item-inner clearfix" style="position: relative;">
                          <div class="  columns sixteen">
                            <div class="s-component s-image s-animation-page-slide_in">
                              <div>
                                <div class="s-component-content">
                                  <div class="s-img-wrapper"><img
                                    src="https://oss.tccms.cn/1owPn5cqKij" alt="" title=""
                                    class="crop-default" data-description=""></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-11 css-1rkmeg4 " id="section-f_0b28939f-5914-4b74-9511-32da23d8c785">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
            <div
              class=" background-image background-image transition lazyloaded  s-new-media-section s-section s-columns-section  center-align   _animate-background background-image"
              data-react-style="{}" style="background-attachment: fixed;">
              <div class="container">
                <div class="columns sixteen">
                  <div class="s-title-group  ">
                    <div class="s-title sb-title-center-align">
                      <div class="s-component s-text s-animation-page-slide_in">
                        <h2 class="s-component-content s-font-title"><p style="font-size: 80%; text-align: center;">
                          <span class="s-text-color-black">多年运营经验输出 助您少走弯路</span></p></h2>
                      </div>
                    </div>
                    <div class="s-subtitle">
                      <div class="s-component s-text s-animation-page-slide_in">
                        <h4 class="s-component-content s-font-heading"><p style="font-size: 80%; text-align: center;">
                          平台搭建之后如何运营？我们结合多年积累的经验让兄弟网站少走弯路</p></h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="s-mh  s-repeatable">
                  <div>
                    <div data-sorting-index="0" class="third columns no-float s-repeatable-item s-last-row">
                      <div class="clearfix">
                        <div>
                          <div class="s-item-media-wrapper s-mhi " style="min-height: 447px;">
                            <div class="s-item-media-group">
                              <div class="s-component s-media  s-animation-page-slide_in">
                                <div>
                                  <div class="s-component-content">
                                    <div class="s-img-wrapper">
                                      <div class="s-ratio-box" style="max-width: 720px; max-height: 858px;">
                                        <div class="s-ratio-fill" style="padding-bottom: 119.17%;"></div>
                                        <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                                        data-description="" width="720" height="858"
                                                                        src="https://oss.tccms.cn/1owPfLMgDVl"
                                                                       >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-sorting-index="1" class="third columns no-float s-repeatable-item s-last-row">
                      <div class="clearfix">
                        <div>
                          <div class="s-item-media-wrapper s-mhi " style="min-height: 447px;">
                            <div class="s-item-media-group">
                              <div class="s-component s-media  s-animation-page-slide_in">
                                <div>
                                  <div class="s-component-content">
                                    <div class="s-img-wrapper">
                                      <div class="s-ratio-box" style="max-width: 720px; max-height: 858px;">
                                        <div class="s-ratio-fill" style="padding-bottom: 119.17%;"></div>
                                        <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                                        data-description="" width="720" height="858"
                                                                        src="https://oss.tccms.cn/1owPfObsg63"
                                                                       >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div data-sorting-index="2" class="third columns no-float s-repeatable-item s-last-row">
                      <div class="clearfix">
                        <div>
                          <div class="s-item-media-wrapper s-mhi " style="min-height: 447px;">
                            <div class="s-item-media-group">
                              <div class="s-component s-media  s-animation-page-slide_in">
                                <div>
                                  <div class="s-component-content">
                                    <div class="s-img-wrapper">
                                      <div class="s-ratio-box" style="max-width: 720px; max-height: 858px;">
                                        <div class="s-ratio-fill" style="padding-bottom: 119.17%;"></div>
                                        <div class="s-img-wrapper"><img alt="" title="" class="crop-default lazyloaded"
                                                                        data-description="" width="720" height="858"
                                                                        src="https://oss.tccms.cn/1owPfRNTNn4"
                                                                      >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slide s-section-12 css-1rkmeg4 " id="section-f_6585e6c7-dd4a-44ca-9d5f-baa3291260ba">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div>
          </div>
        </div>
        <div class="slide s-section-13 css-1rkmeg4 " id="section-f_27d68081-9f76-4874-b7c3-b7fe6afc1fdc">
          <div class="waypoint"></div>
          <a class="section-anchor"></a>
          <div
            class="lazyload background-image background-image transition lazyloaded s-no-bg s-bg-dark s-bg-light-text s-block-section s-section s-top-padding-half s-bottom-padding-half _animate-background background-image"
            data-react-style="{&quot;backgroundColor&quot;:&quot;#1C1C1C&quot;}"
            style="background-color: rgb(28, 28, 28); background-attachment: fixed;">
            <div class="container">
              <div class="columns sixteen"></div>
              <div class="alignment-container ">
                <div class="s-repeatable s-block s-component s-mh">
                  <div
                    class="s-block-item s-repeatable-item s-block-sortable-item s-block-smallFeatureRight  block-component-item s-last-row">
                    <div>
                      <div>
                        <div class="s-block-item-inner clearfix" style="position: relative;">
                          <div class="">
                            <div class="s-block-feature  s-rva right">
                              <div class="six columns right s-rva-media">
                                <div class="s-item-media-group">
                                  <div class="s-component s-media  s-animation-page-slide_in">
                                    <div>
                                      <div class="s-component-content">
                                        <div class="s-img-wrapper">
                                          <div class="s-ratio-box" style="max-width: 120px; max-height: 120px;">
                                            <div class="s-ratio-fill" style="padding-bottom: 100%;"></div>
                                            <div class="s-img-wrapper"><img alt="扫描二维码 咨询详情" title="扫描二维码 咨询详情"
                                                                            class="crop-default lazyloaded"
                                                                            data-description="" width="120" height="120"
                                                                            src="https://oss.tccms.cn/1oeCDiMm9Ur"
                                                                        >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="ten columns right s-rva-text">
                                <div class="s-item-text-group half-offset-right s-animation-page-slide_in">
                                  <div class="s-item-title">
                                    <div class="s-component s-text">
                                      <h3 class="s-component-content s-font-heading">
                                        <div style="font-size:83%;"><span class="#(className)" style="color:#787878;">电话：18305905678<br>邮箱：86060123@qq.com<br>地址：福清市音西街道清宏路10号福清市互联网产业园(一期)5楼B区-3-1单元</span>
                                        </div>
                                      </h3>
                                    </div>
                                  </div>
                                  <div class="s-item-subtitle">
                                    <div class="s-component s-text">
                                      <h6 class="s-component-content s-font-body">
                                        <div style="font-size:83%;"><span class="#(className)" style="color:#787878;">Copyright
                                          © 2022 福建淘才人力资源开发有限公司</span></div>
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="s-item-text">
                                    <div class="s-component s-text">
                                      <div class="s-component-content s-font-body">
                                        <div style="font-size:83%;"><a target="_blank"
                                                                       href="https://beian.miit.gov.cn/"><span
                                          class="#(className)" style="color:#787878;">闽ICP备16025293号-6</span></a></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="waypoint"></div>
        </div>
      </div>
      <div id="strikingly-tooltip-container"></div>
      <div
        class="lazyload background-image background-image lazyloaded s-bg-dark s-footer-section s-section has-new-mobile-actions   _animate-background background-image"
        data-react-style="{}" style="background-attachment: fixed;">
        <div class="container s-layout-vertical">
          <div class="columns sixteen ">
            <div>
              <div class="s-component s-social-media">
                <div>
                  <div class="s-component-content">
                    <ul class="s-social-media-buttons"></ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="s-footer-text">
              <div class="s-component s-text s-animation-page-slide_in"></div>
            </div>
            <div class="s-component s-regulation-info s-animation-page-slide_in">
              <div class="s-component-editor-wrapper"></div>
              <div class="s-regulation-inner">
                <div class="s-regulation-icp s-regulation-item text"><a href="http://beian.miit.gov.cn/"
                                                                        target="_blank">闽ICP备16025293号-6</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-footer-logo-wrapper" style="position: static; width: 100%;"></div>
      <div id="ecommerce-drawer" class="s-category-drawer-wrapper"></div>
      <div id="blog-category-drawer" class="s-category-drawer-wrapper"></div>
      <div class="s-floated-components">
        <div></div>
        <div>
          <div id="s-ecommerce-shopping-cart-wrapper" class="s-ecommerce-shopping-cart-wrapper no-cart"></div>
          <div id="fixedContainer">
            <div id="fixedLoginContainer" class="fixed-login-container"></div>
            <div id="fixedMultiLangSwitcher" class="fixed-multi-lang-container"></div>
          </div>
        </div>
        <div id="s-new-mobile-actions-wrapper" class="">
          <ul class="s-mobile-actions clearfix s-font-body"><a class="s-mobile-actions-item padding-adapting"
                                                               href="https://www.nodetech-inc.com/#1" target=""
                                                               style="width: 25%; display: block;">
            <div class="s-mobile-actions-item-icon"><i class="entypo-home"></i></div>
            <div class="s-mobile-actions-item-text">首页</div>
          </a><a class="s-mobile-actions-item padding-adapting" href="tel:18305905678" target=""
                 style="width: 25%; display: block;">
            <div class="s-mobile-actions-item-icon"><i class="entypo-mobile"></i></div>
            <div class="s-mobile-actions-item-text">联系我们</div>
          </a><a class="s-mobile-actions-item padding-adapting"
                 target="_blank" style="width: 25%; display: block;">
            <div class="s-mobile-actions-item-icon"><i class="entypo-location"></i></div>
            <div class="s-mobile-actions-item-text">地址</div>
          </a><a class="s-mobile-actions-item padding-adapting" href="mailto:86060123@qq.com" target=""
                 style="width: 25%; display: block;">
            <div class="s-mobile-actions-item-icon"><i class="entypo-mail"></i></div>
            <div class="s-mobile-actions-item-text">联系我们</div>
          </a></ul>
        </div>
      </div>
      <div class="shipping-wrapper"></div>
      <div></div>
      <div class="social-thumbnail">
        <div id="lw-share-data" style="display: none;">
          <div class="lws-title">淘才云 - 淘才数字科技城市招聘解决方案</div>
          <div class="lws-description">淘才云 - 淘才数字科技城市招聘解决方案</div>
          <div class="lws-image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'IndexNodeTech',
  components: {},
  props: {},
  watch: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  created() {
  },
  mounted() {
  }
}
</script>
<style src="./IndexNodeTech.css" >
</style>
