<template>
  <div class="index">
    <index-node-tech></index-node-tech>
  </div>
</template>

<script>

import IndexNodeTech from './components/IndexNodeTech.vue';
export default {
  name: 'Index',
  components: {IndexNodeTech},
  data() {
    return {
    }
  },
}
</script>

<style scoped lang="less">
@import "../style/var.less";

</style>
